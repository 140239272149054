import React from 'react';
import Header from '../components/ui/header';
import HeroBlock from '../components/ui/blocks/hero';
import HomeBlock from '../components/ui/blocks/home';
import PartnersBlock from '../components/ui/blocks/partners';
import ContactUsBlock from '../components/ui/blocks/contact-us';
import Footer from '../components/ui/footer';
import { SiteContext } from '../context/settings/site.provider';

const HomePage: React.FC = () => {
  const { state } = React.useContext(SiteContext);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header siteName={state.siteName} siteLogo={state.siteLogo}  />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <HeroBlock siteName={state.siteName} heroTitle={state.siteTitle} heroImg={state.images.heroImg} />
        <HomeBlock whyUsImg={state.images.whyUs} {...state.ourWork} />
        <PartnersBlock partners={state.partners} />
        <ContactUsBlock {...state.contact} />

      </main>

      {/*  Site footer */}
      <Footer siteName={state.siteName} socialMedia={state.socialMedia} />

    </div>
  );
}

export default HomePage;
