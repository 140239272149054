import React from "react";
import { Link } from "react-router-dom";
import PartnersBlock from "../components/ui/blocks/partners";
import Footer from "../components/ui/footer";
import Header from "../components/ui/header";
import { SiteContext } from "../context/settings/site.provider";
import { NAVIGATION } from "../ts-types/custom.types";

const SupportPage: React.FC = () => {
  const { state } = React.useContext(SiteContext);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header siteName={state.siteName} siteLogo={state.siteLogo} />

      {/*  Page content */}
      <main className="flex-grow">
        <section className="relative">
          <div className="max-w-6xl px-4 pt-32 pb-12 mx-auto sm:px-6 md:pt-40 md:pb-20">
            <div className="max-w-5xl mx-auto">
              <h1 className="mb-5 text-center h1" data-aos="zoom-y-out">{state.support.title}</h1>
              <p className="mb-8 text-xl text-gray-600 sm:text-center">{state.support.subTitle}</p>
            </div>

            <PartnersBlock partners={state.partners} />
            <div className="max-w-5xl pb-4 mx-auto whitespace-pre-line">
              <p className="mb-8 text-xl text-gray-600">{state.support.body}</p>

              <h3 className="mb-5 pt-4 h4">Want to read more, click on the button below.</h3>
              <a
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-between p-4 mb-3 text-center transition bg-gray-200 border rounded shadow-md focus:hover:shadow-lg hover:bg-white/70"
                href={state.support.href}>
                <span className="font-semibold">PARTNERSHIP & DONATION</span>
                <svg className="w-4 h-4 ml-6 text-blue-600 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.707 1h-8v2h5.586L1 13.293l1.414 1.414L12.707 4.414V10h2V2a1 1 0 00-1-1z"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="max-w-6xl px-8 py-10 mx-auto bg-gray-900 rounded shadow-2xl md:px-12 md:py-16" data-aos="zoom-y-out">
            <div className="flex flex-col items-center justify-between lg:flex-row">
              <h3 className="mb-6 text-center text-white h3">Looking for sponsorship to further your education.</h3>
              <Link to={NAVIGATION.getStarted} className="inline-flex px-8 py-3 text-white transition bg-blue-600 rounded shadow-lg hover:bg-blue-700">Apply Now</Link>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer siteName={state.siteName} socialMedia={state.socialMedia} />
    </div>
  );
};

export default SupportPage;
