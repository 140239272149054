import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';
import AboutUsPage from './pages/about-us';
import HomePage from './pages/home';
import GetStartedPage from './pages/get-started';
import OpportunitiesPage from './pages/opportunities';
import SupportPage from './pages/support';
import ErrorPage from './pages/error';
//import AdminPage from './pages/admin';
//import LoginPage from './pages/login';
//import RegisterPage from './pages/register';
//import ResetPasswordPage from './pages/reset-password';
import SiteProvider from './context/settings/site.provider';
import { NAVIGATION } from './ts-types/custom.types';
import './assets/style.scss';

const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <SiteProvider>
      <Switch>
        <Route exact path={NAVIGATION.homePage} component={HomePage} />
        <Route path={NAVIGATION.aboutUs} component={AboutUsPage} />
        <Route path={NAVIGATION.getStarted} component={GetStartedPage} />
        <Route path={NAVIGATION.supportUs} component={SupportPage} />
        <Route path={NAVIGATION.opportunities} component={OpportunitiesPage} />
        {/* <Route path="/admin" component={AdminPage} /> */}
        {/* <Route path="/signin" component={LoginPage} /> */}
        {/* <Route path="/signup" component={RegisterPage} /> */}
        {/* <Route path="/reset-password" component={ResetPasswordPage} /> */}
        <Route path="*" component={ErrorPage} />
      </Switch>
    </SiteProvider>
  );
}

export default App;
