type Props = {
  expire: string
  data: unknown,
}

export const getLocalState = (key: string) => {
  try {
    const unSerializedState = localStorage.getItem(key);

    if (null === unSerializedState) {
      return undefined;
    }

    const serializedState: Props = JSON.parse(unSerializedState);

    if (serializedState.expire >= (new Date()).toString()) {
      localStorage.removeItem(key);

      return undefined;
    }

    return serializedState.data;
  } catch {
    return undefined;
  }
};

export const setLocalState = (key: string, value: unknown, expire = 1200 * 24 * 90): void => {
  try {
    const now = new Date();

    const data: Props = {expire: now.setTime(now.getTime() + expire).toString(), data: value};
    const serializedState = JSON.stringify(data);

    localStorage.setItem(key, serializedState);
  } catch {
    // Ignore write errors
  }
}
