import React from "react";
import { Link } from "react-router-dom";
import PartnersBlock from "../components/ui/blocks/partners";
import Footer from "../components/ui/footer";
import Header from "../components/ui/header";
import { SiteContext } from "../context/settings/site.provider";
import { NAVIGATION } from "../ts-types/custom.types";

const AboutUsPage: React.FC = () => {
  const { state } = React.useContext(SiteContext);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header siteName={state.siteName} siteLogo={state.siteLogo} />

      {/*  Page content */}
      <main className="flex-grow">

        <div className="max-w-6xl px-4 pt-32 pb-12 mx-auto sm:px-6 md:pt-40 md:pb-20">
          <div className="max-w-5xl pb-12 mx-auto md:pb-20">
            <h1 className="mb-5 text-center h1" data-aos="zoom-y-out">Get to know us and why we exist.</h1>
            <p className="mb-8 text-xl text-gray-600 sm:text-center">
              {state.aboutUs.why}
            </p>
            <img className="flex-shrink-0 inline object-cover w-full mb-2 rounded-md h-96" src={state.images.aboutUs} alt="Lets Start" />

            <h3 className="my-5 h3">Our Background</h3>
            <p className="mb-8 text-xl text-gray-600">{state.aboutUs.background}</p>

            <h3 id="ethics" className="mb-5 h3">Our Vision and Mission</h3>
            <p className="mb-12 text-xl text-gray-600"><state.aboutUs.purpose /></p>

            <h3 className="mb-5 h3">Our Constitution and Code of Ethics</h3>
            <p className="mb-12 text-xl text-gray-600">{state.aboutUs.ethics.title}</p>
            <div className="grid grid-cols-12 gap-6 mt-6">
              {state.aboutUs.ethics.rules.map((ethic, i) => (
                <div className="col-span-12 sm:col-span-6">
                  <a
                    href={ethic.link}
                    rel="noreferrer"
                    target="_blank"
                    className="flex items-center justify-between p-4 mb-3 font-bold text-center underline transition bg-gray-200 border rounded shadow-md focus:hover:shadow-lg hover:bg-white">
                    {ethic.name}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <PartnersBlock partners={state.partners} />
          <div className="max-w-3xl mx-auto">
            <h3 className="mb-8 h4">Current open positions</h3>
            <div className="-my-3 outline-none">
              {state.aboutUs.jobs.map((item, i) => (
                <a className="flex items-center justify-between p-4 mb-3 text-center transition bg-gray-100 border rounded shadow-md focus:hover:shadow-lg hover:bg-white" key={i} href={item.apply}>
                  <div className="font-medium">{item.position}</div>
                  <svg className="w-4 h-4 ml-6 text-blue-600 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.707 1h-8v2h5.586L1 13.293l1.414 1.414L12.707 4.414V10h2V2a1 1 0 00-1-1z"></path>
                  </svg>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="max-w-6xl px-8 py-10 mx-auto bg-gray-900 rounded shadow-2xl md:px-12 md:py-16" data-aos="zoom-y-out">
          <div className="flex flex-col items-center justify-between lg:flex-row">
            <h3 className="mb-6 text-center text-white h3">Are you Ready to get started.</h3>
            <Link to={NAVIGATION.getStarted} className="inline-flex px-8 py-3 text-white transition bg-blue-600 rounded shadow-lg hover:bg-blue-700">Get Started</Link>
          </div>
        </div>
      </main>

      {/*  Site footer */}
      <Footer siteName={state.siteName} socialMedia={state.socialMedia} />
    </div>
  )
}

export default AboutUsPage;
