import React from 'react';
import { Link } from 'react-router-dom';
import { NAVIGATION, OurWorkType } from '../../../ts-types/custom.types';

type Props = OurWorkType & { whyUsImg: string };

const HomeBlock: React.FC<Props> = ({ title, showCase, whyUsContent, whyUsImg }) => {
  return (
    <React.Fragment>
      <section className="relative">

        {/* Section background (needs .relative class on parent and next sibling elements) */}
        <div className="absolute left-0 right-0 w-px h-20 p-px m-auto transform -translate-y-1/2 bg-gray-200"></div>

        <div className="relative max-w-6xl px-4 mx-auto sm:px-6">
          <div className="pt-12 md:pt-20">

            {/* Section header */}
            <div className="pb-12 mx-auto text-center max-w-7xl md:pb-16">
              <h1 className="mb-4 h2">Invest in your education</h1>
              <div className="text-xl text-left text-gray-600">
                <p className="mb-4">
                  According to Warren Buffet 'the best investment is to invest in oneself', although we all have aspiration, dreams,
                  vision and mission to accomplish yet lives circumstances becomes a blockage to fulfillment of higher heights.
                </p>
                <p className="mb-4">
                  As a charitable organization, we intend to elevate lives through education by giving assistance or sponsorship or scholarship to individuals irrespective of the educational background (with or without),
                  political affiliation, ethnicity etc by supporting or paying between 50 - 100% of ones tuition fees or school fees.
                </p>
                <p>
                  Such an opportunity goes to individuals who wants to upgrade their lives through education to earn{' '}
                  <strong>CERTIFICATE</strong>, <strong>NATIONAL DIPLOMA (DIPLOMA)</strong>, <strong>HIGHER NATIONAL DIPLOMA (ADVANCE DIPLOMA)</strong>, <strong>DEGREE (GRADUATE DIPLOMA)</strong> and <strong>MASTERS (POST GRADUATE DIPLOMA)</strong>{' '}
                  locally and internationally through recognized and accredited institutions.
                </p>
              </div>

              <div className="py-12 lg:py-16 lg:grid lg:grid-cols-3 lg:gap-8" style={{ textAlign: 'left' }}>
                <div>
                  <h2 className="text-3xl font-extrabold text-gray-900">Why choose us</h2>
                  <p className="mt-4 mb-4 text-lg text-gray-500">
                    Can’t find the answer you’re looking for? Reach out to our{' '}
                    <a href={NAVIGATION.contactUs} className="font-medium text-indigo-600 hover:text-indigo-500">
                      customer support
                    </a>{' '}team.
                  </p>
                  <img src={whyUsImg} alt="Why Us" />
                  <div className="flex-shrink-0 m-4">
                  </div>
                </div>
                <div className="mt-12 lg:mt-0 lg:col-span-2">
                  <dl className="space-y-12">
                    {whyUsContent.map((faq) => (
                      <div key={faq.why}>
                        <dt className="text-lg font-medium leading-6 text-gray-900">{faq.why}</dt>
                        <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative">
      <div className="relative max-w-6xl px-4 mx-auto sm:px-6">
        {/* Section header */}
        <div className="max-w-4xl mx-auto text-center pb-9 md:pb-16">
          <h2 className="mb-4 h2">Our Work</h2>
          <p className="text-xl">{title}</p>
        </div>

        {/* Items */}
        <div className="grid items-start max-w-sm gap-6 mx-auto md:grid-cols-2 lg:grid-cols-3 md:max-w-2xl lg:max-w-none">
          {showCase.map((programme, i) => (
            <div key={i} className="relative flex flex-col items-center flex-1 text-center bg-white rounded shadow-xl">
              <div className="flex-shrink-0 ">
                <img src={programme.img} alt="Tears" />
              </div>
              <h4 className="p-6 mb-3 text-xl font-bold leading-snug tracking-tight">{programme.name}</h4>
              <Link to={programme.href} className="w-full py-2 text-base font-medium text-white transition transform bg-gray-900 hover:bg-gray-800">
                Learn more
              </Link>
            </div>
          ))}
        </div>

      </div>
    </section>
    </React.Fragment>
  );
}

export default HomeBlock;
