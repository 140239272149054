import React from "react";
import PartnersBlock from "../components/ui/blocks/partners";
import Footer from "../components/ui/footer";
import Header from "../components/ui/header";
import { SiteContext } from "../context/settings/site.provider";

const OpportunitiesPage: React.FC = () => {
  const { state } = React.useContext(SiteContext);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header siteName={state.siteName} siteLogo={state.siteLogo} />

      {/*  Page content */}
      <main className="flex-grow">
        <section className="relative">
          <div className="max-w-6xl px-4 pt-32 pb-12 mx-auto sm:px-6 md:pt-40 md:pb-20">
            <div className="max-w-5xl mx-auto">
              <h1 className="mb-5 text-center h1" data-aos="zoom-y-out">{state.opportunities.title}</h1>
              <p className="max-w-4xl mx-auto mb-8 text-xl text-center">{state.opportunities.subTitle}</p>
            </div>
              <img className="flex-shrink-0 inline object-cover w-full mb-2 rounded-md h-96" src={state.images.opportunities} alt="Opportunities" />

            <div className="mt-16 mx-auto">
              {state.opportunities.body.map((opportunity, i) => (
                <React.Fragment key={i}>
                  {opportunity.title && <h3 className="my-5 h3">{opportunity.title}</h3>}
                  <p className="mb-8 text-xl">{opportunity.content}</p>
                </React.Fragment>
              ))}
            </div>
          </div>
          <PartnersBlock partners={state.partners} />
        </section>
      </main>

      {/*  Site footer */}
      <Footer siteName={state.siteName} socialMedia={state.socialMedia} />

    </div>
  )
}

export default OpportunitiesPage;
