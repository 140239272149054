import { ReactElement, ReactSVGElement, SVGAttributes } from "react";

export const NAVIGATION = {
  homePage: '/',
  getStarted: '/get-started',
  aboutUs: '/about-us',
  contactUs: '/#contact-us',
  supportUs: '/support',
  opportunities: '/opportunities',
  blog: '/blog',
  constitution: '/about-us#ethics',
};

export type SettingsType = {
  siteName: string,
  siteLogo: string,
  siteTitle: string,
  navigation: typeof NAVIGATION,
  images: Record<string, string>,
  aboutUs: AboutUsType,
  ourWork: OurWorkType,
  partners: PartnerType[],
  opportunities: OpportunitiesType,
  contact: ContactType,
  support: SupportType,
  getStarted: GetStartedType[],
  socialMedia: SocialMediaType[],
};

export type AboutUsType = {
  why: string,
  background: string,
  purpose: () => ReactElement,
  ethics: {
    title: string,
    rules: { name: string, link: string, }[],
  },
  jobs: JobType[]
};

export type SupportType = {
  title: string,
  subTitle: string,
  href: string,
  body: string,
};

export type OurWorkType = {
  title: string,
  showCase: (LinkType & { img: string })[],
  whyUsContent: { why: string, answer: string }[],
};

export type PartnerType = {
  partner: string,
  img: string,
  href: string,
};

export type OpportunitiesType = {
  title: string,
  subTitle: string,
  body: { title: string, content: string }[],
};

export type ContactType = {
  open: string,
  address: string,
  phone: string,
  email: string,
  gpa: string,
};

export type GetStartedType = {
  tab: number,
  title: string,
  body: string,
  programmes: ProgrammeType[],
};

export type ProgrammeType = {
  title: string,
  description: string,
  body: string[],
};

export type JobType = {
  position: string,
  apply: string,
};

export type LinkType = {
  name: string,
  href: string,
};

export type SocialMediaType = {
  icon: (props: SVGAttributes<SVGElement>) => ReactElement<ReactSVGElement>,
} & LinkType;
