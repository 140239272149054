import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NAVIGATION } from '../../ts-types/custom.types';
import AnnouncementBlock from './blocks/announcement';
import { getLocalState, setLocalState } from '../../utils/localStorage';

type Props = {
  siteName: string,
  siteLogo: string,
};

const Header: React.FC<Props> = ({ siteName, siteLogo }) => {
  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <header className="fixed z-30 w-full transition duration-300">
      {!getLocalState('cookie-policy') && <AnnouncementBlock
        handleClose={(e) => setLocalState('cookie-policy', true)}
        top={true}
        shortTitle="Browsing with cookies - "
        title="By using this website you agree to our Cookie Policy"
        link="/#" />}
      <Popover className="relative bg-white md:bg-opacity-90">
        {({ open }) => (
          <React.Fragment>
            {!top && <div className="absolute inset-0 z-30 shadow-lg pointer-events-none" aria-hidden="true" />}
            <div className="relative z-20">
              <div className="flex items-center justify-between max-w-6xl px-4 py-5 mx-auto sm:px-6 sm:py-4 lg:px-9 md:justify-start md:space-x-10">
                <div>
                  <a href={NAVIGATION.homePage} className="flex">
                    <span className="sr-only">{siteName}</span>
                    <img className="w-auto h-8 sm:h-10" src={siteLogo} alt={siteName} />
                  </a>
                </div>
                <div className="-my-2 -mr-2 md:hidden">
                  <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="w-6 h-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <div className="hidden ml-0 md:flex-1 md:flex md:items-center md:justify-between">
                  <Popover.Group as="nav" className="flex justify-center flex-1 flex-grow space-x-10">
                    <Link to={NAVIGATION.opportunities} className="text-base font-medium text-gray-500 hover:text-gray-900">OPPORTUNITIES</Link>
                    <Link to={NAVIGATION.aboutUs} className="text-base font-medium text-gray-500 hover:text-gray-900">ABOUT</Link>
                    <Link to={NAVIGATION.blog} className="text-base font-medium text-gray-500 hover:text-gray-900">BLOG</Link>
                    <Link to={NAVIGATION.supportUs} className="text-base font-medium text-gray-500 hover:text-gray-900">SUPPORT</Link>
                  </Popover.Group>
                  <div className="flex items-center md:ml-12">
                    <Link to={NAVIGATION.getStarted} className="flex items-center justify-center px-4 py-2 ml-3 text-gray-200 bg-blue-600 btn-sm hover:bg-blue-700">
                      <span>Get Started</span>
                      <svg className="flex-shrink-0 w-3 h-3 ml-2 -mr-1 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <Transition
              show={open}
              as={React.Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute inset-x-0 top-0 z-30 p-2 transition origin-top-right transform md:hidden"
              >
                <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 focus:outline-none ring-black ring-opacity-5 divide-gray-50">
                  <div className="px-5 pt-5 pb-6 sm:pb-8">
                    <div className="flex items-center justify-between">
                      <div>
                        <a href={NAVIGATION.homePage} className="flex">
                          <img className="w-auto h-8" src={siteLogo} alt={siteName} />
                        </a>
                      </div>
                      <div>
                        <Link
                          to={NAVIGATION.getStarted}
                          className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm focus:outline-none hover:bg-blue-700"
                        >
                          Get Started
                        </Link>
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="w-6 h-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                  </div>
                  <div className="px-5 py-6 sm:text-center">
                    <div className="grid grid-cols-2 gap-4">
                      <Link to={NAVIGATION.aboutUs} className="text-base font-medium text-gray-900 rounded-md hover:text-gray-700">
                        About Us
                      </Link>

                      <a href={NAVIGATION.constitution} className="text-base font-medium text-gray-900 rounded-md hover:text-gray-700">
                        Constitution
                      </a>

                      <Link to={NAVIGATION.supportUs} className="text-base font-medium text-gray-900 rounded-md hover:text-gray-700">
                        Sponsorship
                      </Link>

                      <a href={NAVIGATION.contactUs} className="text-base font-medium text-gray-900 rounded-md hover:text-gray-700">
                        Contact Us
                      </a>
                    </div>
                    <div className="mt-6">
                      <Link to={NAVIGATION.getStarted} className="font-medium text-blue-600 hover:text-blue-500">
                        {' '}
                        View all programmes <span aria-hidden="true">&rarr;</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </React.Fragment>
        )}
      </Popover>
    </header>
  );
}

export default Header;
