import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline";
import React from "react";

type Props = {
  top?: boolean,
  title: string,
  shortTitle: string,
  link: string,
  handleClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
};

const AnnouncementBlock: React.FC<Props> = ({ top = false, title, shortTitle, link, handleClose }) => {
 const handleClosed = (e) => {
    if (handleClose) {
      handleClose(e);
    }

    e.currentTarget.parentNode.parentNode.parentElement.remove();
  }

  if (top) {
    return (
      <div className="relative bg-gradient-to-r from-blue-500 to-teal-400">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium text-white">
              <span className="md:hidden">{shortTitle}</span>
              <span className="hidden md:inline">{title}</span>
              <span className="md:block sm:ml-2 md:inline-block">
                <a href={link} className="text-white font-bold underline">{' '} Learn more</a>
              </span>
            </p>
          </div>
          <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
            <button
              type="button"
              className="flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
              onClick={handleClosed}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-2 rounded-lg bg-gradient-to-r from-blue-500 to-teal-400 shadow-lg sm:p-3">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-indigo-800">
                <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 font-medium text-white truncate">
                <span className="md:hidden">{shortTitle}</span>
                <span className="hidden md:inline">{title}</span>
              </p>
            </div>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <a
                href={link}
                className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
              >
                Learn more
              </a>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                type="button"
                className="-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
                onClick={handleClosed}
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnnouncementBlock;
