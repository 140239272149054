import { Transition } from "@headlessui/react";
import React from "react";
import Footer from "../components/ui/footer";
import Header from "../components/ui/header";
import PartnersBlock from "../components/ui/blocks/partners";
import { SiteContext } from "../context/settings/site.provider";

const GetStartedPage: React.FC = () => {
  const [tab, setTab] = React.useState(1);
  const tabs = React.useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  };

  React.useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const { state } = React.useContext(SiteContext);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header siteName={state.siteName} siteLogo={state.siteLogo} />

      {/* Section header */}
      <main className="flex-grow">
        <div className="max-w-6xl px-4 pt-32 pb-12 mx-auto text-center md:pb-20 md:pt-40 sm:px-6">
          <h1 className="h1" data-aos="zoom-y-out">Let's Get Started</h1>
          <p className="mt-4 text-xl leading-6 text-gray-600">
            Apply today and benefit a scholarship from any of our programmes. We always accepting new students, do not be left out.
          </p>
          <img className="flex-shrink-0 inline object-none w-full mt-5 rounded-md h-96" src={state.images.getStarted} alt="Lets Start" />
        </div>

        {/*  Page content */}
        <section className="relative">

          {/* Section background (needs .relative class on parent and next sibling elements) */}
          <div className="absolute inset-0 mb-16 bg-gray-100 pointer-events-none" aria-hidden="true"></div>

          <div className="relative max-w-6xl px-4 mx-auto sm:px-6">

            {/* Section content */}
            <div className="md:grid md:grid-cols-12 md:gap-6">

              {/* Content */}
              <div className="max-w-xl mx-auto md:max-w-none md:w-full md:col-span-3" data-aos="fade-right">
                {/* Tabs buttons */}
                <div className="mb-8 md:mb-0">
                  {state.getStarted.map((item, i) => (
                    <a
                      className={`flex items-center text-lg p-5 rounded border transition duration-300 mb-3 ${tab !== item.tab ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                      href={`#${item.tab}`}
                      key={i}
                      onClick={(e) => { e.preventDefault(); setTab(item.tab); }}
                    >
                      <span className="mb-1 text-sm font-bold leading-snug tracking-tight">{item.title}</span>
                    </a>
                  ))}
                </div>
              </div>

              {/* Tabs items */}
              <div className="max-w-xl mx-auto mb-8 md:max-w-none md:w-full md:col-span-9 md:mb-0 md:order-1" data-aos="zoom-y-out" ref={tabs}>
                <div className="relative flex flex-col text-left">
                  {state.getStarted.map((item, i) => (
                    <Transition
                      key={i}
                      show={tab === item.tab}
                      appear={true}
                      enter="transition ease-in-out duration-700 transform order-first"
                      enterFrom="opacity-0 translate-y-16"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in-out duration-300 transform absolute"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-16"
                    >
                      <div className="relative inline-flex flex-col flex-grow w-full text-lg">
                        <h1 className="mb-4 h3">{item.title}</h1>
                        <p className="mb-8">{item.body}</p>

                        {/* Programmes */}
                        {item.programmes.map((programme, i) => (
                          <React.Fragment key={i}>
                            {programme.title && <h2 className="my-4 text-xl sm:text-2xl h4">{programme.title}</h2>}
                            {programme.description && <p className="mb-5">{programme.description}</p>}
                            <ol className={`${1 === tab ? 'text-base' : 'text-sm sm:text-base uppercase font-bold'} list-decimal list-inside`}>
                              {programme.body.map((entry, i) => (<li key={i} className="py-1">{entry}</li>))}
                            </ol>
                            <p className="my-4">
                              For more details in respect of {1 === tab ? "Entry requirements" : "Programmes"}, applicants are advised to{' '}
                              <a href="/#contact-us" className="font-bold underline">contact us</a> for additional information on the various programmes.
                            </p>
                          </React.Fragment>
                        ))}
                      </div>
                    </Transition>
                  ))}
                </div>
              </div>
            </div>
            <PartnersBlock partners={state.partners} />
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer siteName={state.siteName} socialMedia={state.socialMedia} />
    </div>
  );
}

export default GetStartedPage;
