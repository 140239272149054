import React from "react";
import { NAVIGATION, PartnerType } from "../../../ts-types/custom.types";

const becomeAPartner: PartnerType = {
  partner: 'Partnership',
  img: require('../../../images/s8.png').default,
  href: '/support',
};

type Props = { partners: typeof becomeAPartner[] };

const PartnersBlock: React.FC<Props> = ({ partners }) => {
  const sortMax = partners.length % 3;

  if (0 === sortMax) {
    partners.push(becomeAPartner);
  }

  partners = partners.concat(2 === sortMax ? becomeAPartner : [becomeAPartner, becomeAPartner]);

  const partnerReferrer = (isPartner: boolean, link: string) => {
    if (isPartner) {
      return { target: "_blank", href: link, rel: "noreferrer" };
    }

    return { href: NAVIGATION.supportUs + "#partnership" };
  };

  return (
    <div className="mb-8 shadow-lg bg-gray-50">
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <p className="text-2xl font-bold tracking-wider text-center text-gray-800 uppercase ">
          Our Partners and Sponsors
        </p>
        <div id="partnership" className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
          {partners.map((partner, i) => (
            <div key={i} className="flex justify-center col-span-1 px-8 py-8 bg-white">
              <a {...partnerReferrer("Partnership" !== partner.partner, partner.href)}>
                <img className="object-cover w-64 max-h-32" src={partner.img} alt={partner.partner} />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PartnersBlock;
