import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/ui/footer";
import Header from "../components/ui/header";
import { SiteContext } from "../context/settings/site.provider";

const ErrorPage: React.FC = () => {
  const { state } = React.useContext(SiteContext);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header siteName={state.siteName} siteLogo={state.siteLogo} />

      {/*  Page content */}
      <main className="flex-grow">
        <section className="relative">
          <div className="max-w-6xl px-4 pb-12 mx-auto pt-36 md:pb-20 md:pt-40 sm:px-6">
            <div className="max-w-3xl mx-auto text-center">
              <div className="flex justify-center flex-shrink-0 mb-4">
                <img
                  className="w-auto h-20 sm:h-52"
                  src="https://pixiefy.com/themes/marshall/html/v24/images/v24/workers.png"
                  alt="Construction"
                />
              </div>
              <h1 className="h1">Sorry, This page is under construction.</h1>
              <Link
                to="/"
                className="inline-flex px-8 py-3 mt-8 text-white transition bg-blue-600 rounded shadow-lg hover:bg-blue-700"
              >
                Go back home
              </Link>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer siteName={state.siteName} socialMedia={state.socialMedia} />
    </div>
  );
};

export default ErrorPage;
