import React from "react";
import { NAVIGATION, SettingsType } from "../../ts-types/custom.types";

const initialState: SettingsType = {
  siteName: "Scholar Tears Drop",
  siteLogo: require('../../images/logo.png').default,
  siteTitle: "Do you want to get back in school, are you looking for a scholarship, Earn a 100% tuition FREE scholarship",
  navigation: NAVIGATION,
  images: {
    aboutUs: require('../../images/s6.jpg').default,
    getStarted: require('../../images/s5.jpg').default,
    whyUs: require('../../images/s4.jpg').default,
    heroImg: require('../../images/banner.jpg').default,
    opportunities: require('../../images/s9.jpg').default,
  },
  aboutUs: {
    why: `
      Scholar Tears Drop (STD) concept was established in the year 2017 by Ernest Nkansah Asare ( Executive Director) as a Not-For-Profit Organization,
      We are a private owned non-partisan, independent youth leadership, and an educational organization in Accra, Ghana.
    `,
    background: `
      Scholar Tears Drop is a Not-For-Profit Organization that intents to hold forums/webinars/seminars for students to educate them on
      accreditation, course selection, career guidance, entrepreneurship etc, our seminars or engagements are professionally developed.
      and branded to empower young people in Ghana and beyond, our programs are designed to suit the need, wish or desires of individuals.
      The forum is an educational platform where students in Ghana meet with career oriented men and women in their schools,
      communities and non-denominational religious bodies to inspire individuals come to realization of their dreams.
      We inspire the youth not to wait for tomorrow before they start to think or make a change.
      We are not in position to complain, but to develop self-assurance as we live to relieve others from their pains,
      we are with global mind of building young ones to be "assets" and not "liability".
      The foundation has mentored and inspired youths at leadership training, workshop and conferences. If we look to the future, when we talk about outsourcing jobs,
      when we talk about global competitiveness, youth empowerment and our efficiency, none of them matters very much unless we have the appropriate leaders, good economies,
      a proper educational systems for young ones who are the workforce for tomorrow.
    `,
    purpose: () => (
      <React.Fragment>
        Our core <strong>Vision</strong> is to unlock potentials using education as tool while our <strong>Mission</strong> is to restore hope to those facing challenges
        in having an education. Talents can take you far, but having both talent and education, can take you further.
        As an organization we cannot do everything alone, that why we are into partnership with other corporate bodies. So collective with support and God,
        we can change situations evolving and involving around such individuals with the power of education.
      </React.Fragment>
    ),
    ethics: {
      title: `
        As an organization, there are rules and regulations that governs us. Based on these guidelines our organization dwells upon.
        Click any of the links before to read our CODE OF ETHICS and/or CONSTITUTION.
      `,
      rules: [
        {
          name: "CODE AND ETHICS",
          link: "https://drive.google.com/file/d/1aNDCuy7BOCiy7LRUnOdrnWH6QjY_oUuD/view?usp=sharing",
        },
        {
          name: "CONSTITUTION",
          link: "https://drive.google.com/file/d/1lTSkGHL6Mw6DgTjQR9VRoN-_18szgBz_/view?usp=sharing",
        },
      ],
    },
    jobs: [
      {
        position: "Social Media Manager",
        apply: "/#"
      },
    ],
  },
  ourWork: {
    title: "STD puts great pride in the parts that make up its whole.",
    showCase: [
      {
        name: 'Scholarship Programs',
        href: NAVIGATION.getStarted,
        img: require('../../images/screen_3x.jpg').default,
      },
      {
        name: 'Education Empowerment',
        href: NAVIGATION.aboutUs,
        img: require('../../images/s3.jpg').default,
      },
      {
        name: 'Team Work',
        href: NAVIGATION.supportUs,
        img: require('../../images/screen_2x.jpg').default,
      },
    ],
    whyUsContent: [
      {
        why: '1. We offer priority to every applicant',
        answer: 'Ready and serious applicants are offered top priority in application process.',
      },
      {
        why: '2. No hidden fees charged',
        answer: 'You will not be charged any hidden fee aside from the bill discussed with you by the organization.',
      },
      {
        why: '3. Scholarship for everyone',
        answer: 'We offer both Undergraduate and Post Graduate Programmes, as long as you are eligible for it.',
      },
      {
        why: '4. Employment/Opportunities Ready',
        answer: 'Explore wide range of opportunities with us both schooling and after, including getting a job.',
      },
    ],
  },
  partners: [
    {
      partner: 'Passion Yoghurt Ghana',
      img: require('../../images/s7.jpg').default,
      href: 'https://fb.com/PassionYoghurtGH',
    },
  ],
  opportunities: {
    title: "Explore Opportunity Programmes",
    subTitle: `
      Hello There, Are you looking for a scholarship to further your education, an internship opportunity, a job, becoming a volunteer, or attend a conference & workshop related to your field of work or study.

    `,
    body: [
      {
        title: "Internships",
        content: `
          The internship is an opportunity that is offered by an employer to mainly students, undergraduates, or recent graduates and they are referred to as interns.
          These interns work at a firm or Company for a fixed period of time, usually 1-6 months. Internships are usually part-time if offered during a university semester and full-time if offered during the vacation periods.
          Finding an internship opportunity in around the world is always not easy, CONTACT US NOW and we can help you attain an internship opportunity whether in Ghana, Africa and around the globe.
        `,
      },
      {
        title: "Entrepreneurship",
        content: `
          Entrepreneurship is the act of setting up, organizing managing, and sustaining an existing or new business venture or enterprise. A person who is into entrepreneurship is known as an entrepreneur.
          They are often called the risk bearer because they risk starting off a business against all odds. Usually, most businesses start from a small scale before growing out into a very large and massive enterprise.
          The process of growth often comes with a lot of perseverance and an effective management system. We as an organization can help find Entrepreneurial Contest, funding or grant for your business.
        `
      },
      {
        title: "",
        content: `
          We can keep listing more and more of opportunities we offer such as Contests opportunities, Conferences and Workshops, Research and grants, plus many more opportunities you can grab.
          Do CONTACT US, so we can discuss on what you want, and work towards attaining it. Scholar Tears Drop is here for everyone, don't be left out.
        `
      },
    ],
  },
  contact: {
    open: "Our office opens 8.AM to 5.PM from Monday to Saturday, you can also call or whatsApp.",
    address: "35 Oti Street, Malejor School Junction - Adenta Constituency. Accra, Ghana.",
    phone: "+233 55 762 2636",
    email: "scholartears@gmail.com",
    gpa: "EG-232-2323-22",
  },
  support: {
    title: "Become a Sponsor or Partner",
    href: "https://drive.google.com/file/d/1i9rloNwuiCXyuczDLdR1OkNtFFksGxFx/view?usp=sharing",
    subTitle: `
      Hello and welcome to our partnership and sponsorship page,
      As an organization, we would like to express our sincere gratitude for all generous donations given to our course.
      We really appreciate it.
    `,
    body: `
    Scholar Tears Drop was established in the year 2017 as a Not-For-Profit Organization in Accra, Ghana. Over time, a number of students have benefited from our scholarship programmes, and we are happy to see a lot more people furthering their education through us.

    We wouldn’t be able to support a lot more students on their educational journeys without sponsors and partners. Education in present days has seen much value, having an educated society unlocks potentials and business growth. Every donation made will help more people attain the education they deserve and become better in life.

    If you or your company consider becoming a one-time donor, a sponsor, or a featured partner of our organization, contact us at [+233-55-762-2636/scholartears@gmail.com] to learn more about our potential partnership. Again, if you want to contact us further for other opportunities or just to chat in general, feel free to contact us.
    `,
  },
  getStarted: [
    {
      tab: 1,
      title: "Entry Requirements",
      body: `
        Academic entry requirements for each programme varies, To obtain an education through us, you must ensure your entry requirements
        conforms to the standards set by the National Accreditation Board and the institute we are in partnership with.
        The admission requirements are as follows to interested applicants which includes being a citizen of Ghana:
      `,
      programmes: [
        {
          title: "Postgraduate Programme Requirement",
          description: "To be admitted for a Postgraduate programme, a candidate must satisfy the following minimum requirements besides conditions stipulated for a particular programme:",
          body: [
            "Four (4) passport pictures.",
            "A minimum of two years working experience is required from all applicants.",
            "Bachelors’ Degree Certificate and Transcript (for those applying for  Masters’ Degree programmes).",
            "If you have multiple name, provide an ORIGINAL copy of your Gazette/Marriage certificate where name has changed.",
            "One (1) copy of any National Identification card, ie. Passport, Voters ID, Ghana Card, Driver’s License, etc.",
            "For purposes of assessing an applicant’s eligibility, he/she may be required to take an entrance exam and/or interview at the Institute.",
          ],
        },
        {
          title: "Continuous Professional Development Requirement",
          description: "To be admitted for a professional development programme, a candidate must satisfy the following minimum requirements:",
          body: [
            "Four (4) passport pictures.",
            "Obtain a eligible accredited DEGREE certificate including transcript.",
            "You must have working services or interest in the programmes' field.",
            "Must be able to give a tangible reasons wanting to continue on such field with requisite documents to ascertain his or her reasons.",
            "If you have multiple name, provide an ORIGINAL copy of your Gazette/Marriage certificate where name has changed.",
            "One (1) copy of any National Identification card, ie. Passport, Voters ID, Ghana Card, Driver’s License, etc.",
          ],
        },
        {
          title: "Undergraduate Programme Requirement",
          description: "To be admitted for a Undergraduate programme, a candidate must satisfy the following minimum requirements:",
          body: [
            "Four (4) passport pictures.",
            "One must be eighteen (18) years and above.",
            "Obtain a well accredited Junior High, Senior High and above certification with passes (A1 - D7).",
            "If you have multiple name, provide an ORIGINAL copy of your Gazette/Marriage certificate where name has changed.",
            "One (1) copy of any National Identification card, ie. Passport, Voters ID, Ghana Card, Driver’s License, etc.",
          ],
        },
        {
          title: "Vocational Programme Requirement",
          description: "To be admitted for a Vocational programme, a candidate must be willing and determined to learn and work:",
          body: [
            "Four (4) passport pictures.",
            "Little or no educational background.",
            "One must be eighteen (18) years and above.",
            "If you have multiple name, provide an ORIGINAL copy of your Gazette/Marriage certificate where name has changed.",
            "One (1) copy of any National Identification card, ie. Passport, Voters ID, Ghana Card, Driver’s License, etc.",
          ],
        },
      ],
    },
    {
      tab: 2,
      title: 'Postgraduate Programmes',
      body: `
        Before selecting any of our postgraduate programmes you will be applying for with us, do take note of the entry requirements.
      `,
      programmes: [
        {
          title: "",
          description: "",
          body: [
            "Education and Training",
            "Health and Social Care",
            "Business Administration / Management",
            "Accounting and Finance",
            "Oil and Gas Management",
            "Data Science",
            "Executive Management",
            "Occupational Health and Safety",
            "Human Resource Management",
            "Project Management",
            "Advertising and Public Administration",
            "Tourism and Business Studies",
            "International Business Studies",
            "Leadership",
            "And Many More ...",
          ],
        },
      ],
    },
    {
      tab: 3,
      title: 'Continuous Professional Development Programmes',
      body: `
        Before selecting any of our continuos professional development programmes you will be applying for with us, do take note of the entry requirements.
      `,
      programmes: [
        {
          title: "",
          description: "",
          body: [
            "Education and Training",
            "Health and Social Care",
            "Carpentry",
            "Plumbing",
            "Architect",
            "Mechanical Engineering",
            "And Many More ...",
          ],
        }
      ],
    },
    {
      tab: 4,
      title: 'Undergraduate Programmes',
      body: `
        Before selecting any of our undergraduate programmes you will be applying for with us, do take note of the entry requirements.
      `,
      programmes: [
        {
          title: "",
          description: "",
          body: [
            "Education and Training",
            "Health and Social Care",
            "Business Administration / Management",
            "Accounting and Finance",
            "Oil and Gas Management",
            "Data Science",
            "Executive Management",
            "Occupational Health and Safety",
            "Human Resource Management",
            "Strategic Management",
            "Research Methods",
            "Project Management",
            "Risk Management",
            "Tourism and Hospitality Management",
            "Logistics and Supply Chain",
            "Public Administration",
            "Clinical Aesthetic Injectable",
            "Purchasing and Supply Chain",
            "Office Management / Secretaryship",
            "Marketing Management",
            "Project Management",
            "Advertising and Public Administration",
            "Tourism and Business Studies",
            "International Business Studies",
            "Leadership",
            "Hospitality Management",
            "Journalism and Media Studies",
            "Health and Safety Management",
            "Marine Engineering / Maritime Management",
            "Sports Management",
            "Religious Studies",
            "Computer Engineering / Computer Science",
            "Law",
            "Security Management",
            "Banking and Finance",
            "And Many More ...",
          ],
        },
      ],
    },
    {
      tab: 5,
      title: 'Vocational Programmes',
      body: `
        Before selecting any of our vocational programmes you will be applying for with us, do take note of the entry requirements.
      `,
      programmes: [
        {
          title: "",
          description: "",
          body: [
            "Graphic Design",
            "App Development",
            "3D Animation",
            "Video Editing",
            "Website Design",
            "Camera Handling",
            "Hardware",
            "Photography",
            "English",
            "Chinese",
            "French",
            "German",
            "Arabic",
            "Italian",
            "Portuguese",
            "Dutch",
            "Spanish",
            "Cosmetology",
            "Fashion Design",
            "Event Decoration",
            "Catering",
            "Food Processing",
            "Soap Making",
            "Juice Training & Production",
            "Hairstyle",
            "Personal Grooming",
            "Yoghurt Training",
            "Fountain Training",
            "Bio Digester Training",
            "And Many More ...",
          ],
        },
      ],
    },
  ],
  socialMedia: [
    {
      name: 'Facebook',
      href: 'https://fb.com/scholartearsdrop',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://instagram.com/scholartears',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/scholartearsdrop',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
};

type ACTIONTYPE = { type: "update"; payload: {} };

export type SiteProviderProps = {
  state: SettingsType,
  dispatch: React.Dispatch<ACTIONTYPE>
}

export const SiteContext = React.createContext({ } as SiteProviderProps);

function reducer(state: typeof initialState, action: ACTIONTYPE) {
  switch (action.type) {
    case "update":
      const updated = action.payload;

      console.log(updated);

      return { ...state, updated };
    default:
      return state;
  }
}

const SiteProvider: React.FC<{ children: React.ReactNode, }> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <SiteContext.Provider value={{ state, dispatch }}>
      {children}
    </SiteContext.Provider>
  )
}

export default SiteProvider;
